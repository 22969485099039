@import '../../assets/styles/styles';

.container {
  &__table {
    position: relative;
    display: flex;
    flex-direction: row;
    &__topLeftFixedGridContainer {
      color: black;
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      &__grid {
        &__cell {
          background-color: $purple-light;
          font-weight: bold;
        }
      }
    }
    &__leftFixedGridContainer {
      position: absolute;
      left: 0;
      background-color: $gray-1;
      z-index: 10;
    }
    &__middleGridContainer {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      &__header {
        &__grid {
          &__cell {
            background-color: $purple-light;
            z-index: 10;
            color: black;
            font-weight: bold;
          }
        }
      }
      &__grid {
        &__cell {
          color: black;
        }
      }
    }
    &__topRightFixedGridContainer {
      &__grid {
        &__cell {
          background-color: $purple-light;
          color: black;
          font-weight: bold;
        }
      }
    }
    &__rightFixedGridContainer {
      &__grid {
        overflow: hidden !important;
        &__cell {
          color: black;
        }
      }
    }
  }
}

.GridColumn {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.LeftSideGridContainer {
  z-index: 10;
}

.LeftSideGrid {
  overflow: hidden !important;
}
.HeaderGrid {
  width: 100%;
  overflow: hidden !important;
}
.BodyGrid {
  width: 100%;
}

.evenRow {
  background-color: $gray-1;
}
.oddRow {
  background-color: $white;
}

.genericCell {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: $font-book;
}

.headerCell,
.leftCell {
  font-weight: bold;
}
