@import '../assets/styles/styles';

.MuiFormLabel-root {
  font-family: $font-medium !important;
}

.MuiInputBase-input {
  font-family: $font-book !important;
}

.MuiButton-label {
  font-family: $font-demi !important;
}
