$purple: #7564ef;
$purple-light: #f8f7ff;
$white: #ffffff;
$blue-faded: #f1f7fb;
$blue-ligher: #fafdff;
$blue-light: #5cc1fb;
$blue-dark: #242a52;
$pink: #ff667a;
$pink-light: #fff7f7;
$yellow-faded: #fefdf9;
$yellow: #fedc00;
$orange-faded: #fffcfa;
$orange-lighter: #fffafb;
$orange-light: #fff7f0;
$orange: #ff9a3d;
$green: #21cc9e;
$green-light: #fafffd;
$gray-1: #fafafa;
$gray-2: #f6f6f6;
$gray-3: #e6e6e6;
$gray-4: #808080;
$gray-5: #a0abbf;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary":    $purple,
    "secondary":  $pink,
    "success":    $green,
    "info":       $blue-light,
    "warning":    $orange,
    "danger":     $orange,
    "light":      $purple-light,
    "dark":       $blue-dark
  ),
  $theme-colors
);

// Border

@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value;
  }
}

// Background

@each $color, $value in $theme-colors {
  @include bg-variant(".bg-#{$color}", $value);
}