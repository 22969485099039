@import '../mixins/font-family';

@include font-face('futura-demi', './../../fonts/futura/Futura-PT-Demi');
@include font-face('futura-medium', './../../fonts/futura/Futura-PT-Medium');
@include font-face('futura-book', './../../fonts/futura/Futura-PT-Book');
@include font-face('futura-light', './../../fonts/futura/Futura-PT-Light');
@include font-face(
  'futura-demi-italic',
  './../../fonts/futura/Futura-PT-Demi-Italic'
);
