.container {
  height: 100%;
  display: grid;
  gap: 10px;
}

.item {
  background-color: #2fa597;
  border-radius: 10px;
}

.a {
  grid-column: 1 / 4;
  grid-row: 1 / 2;
}

.b {
  grid-column: 1 / 4;
  grid-row: 2 / 3;
}

.c {
  grid-column: 1 / 3;
  grid-row: 3 / 4;
}

.d {
  grid-column: 3 / 4;
  grid-row: 3 / 4;
}
